import AccountSpecialistCard from "components/Header/components/Support/AccountSpecialistCard";
import SupportForm from "components/Header/components/Support/SupportForm";
import {
  ApiEmployeeRecordDTO,
  ApiLayoutSiteDataDTO,
} from "layouts/api/layoutAPI.types";
import ChatButton from "components/Header/components/Support/ChatButton";

export type SupportPopOverProps = {
  className?: string;
  accountSpecialist: ApiEmployeeRecordDTO;
  encryptedUserIndex: string;
  isSpecialistPhoneNumberVisible: boolean;
  isSwitchboardEnabled: boolean;
  siteData: ApiLayoutSiteDataDTO;
  onSubmit: () => void;
};

export default function SupportPopOver({
  className,
  accountSpecialist,
  encryptedUserIndex,
  isSpecialistPhoneNumberVisible,
  isSwitchboardEnabled,
  siteData,
  onSubmit,
}: SupportPopOverProps): JSX.Element {
  return (
    <div className={className}>
      <div className="rounded-lg max-h-[calc(100vh-160px)] overflow-auto p-8 bg-white flex flex-col gap-8 border">
        <div className="flex justify-between">
          <h1 className="mb-1.5 text-3xl font-bold">How can we help?</h1>
          {isSwitchboardEnabled && (
            <ChatButton
              encryptedUserIndex={encryptedUserIndex}
              siteData={siteData}
              id="support-chat-button"
            />
          )}
        </div>
        <AccountSpecialistCard
          accountSpecialist={accountSpecialist}
          isSpecialistPhoneNumberVisible={isSpecialistPhoneNumberVisible}
          className="border rounded-lg p-4"
          photoClassName="w-16"
        />
        <div className="flex flex-col gap-x-4 gap-y-8">
          <SupportForm onFinish={onSubmit} />
        </div>
      </div>
    </div>
  );
}
