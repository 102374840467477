import { ReactNode, useMemo, useState } from "react";
import ToastWrapper from "components/Toast/ToastWrapper";
import { ToastContext } from "contexts/toast/ToastContext";
import { uuid } from "util/string";
import { ToastType } from "contexts/toast/Toast.types";

const displayDuration = 4000;
const transitionDuration = 700;

type Toast = {
  element: JSX.Element;
  id: string;
};

export default function ToastProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const value = useMemo(
    () => ({
      showToast: (
        content: JSX.Element | string,
        type: ToastType = "custom"
      ): void => {
        const id = uuid();

        const removeToast = (): void => {
          setToasts((previousToasts) =>
            previousToasts.filter((t) => t.id !== id)
          );
        };

        setToasts((previousToasts) => [
          {
            element: (
              <ToastWrapper
                key={id}
                closeToast={removeToast}
                displayDuration={displayDuration}
                transitionDuration={transitionDuration}
                type={type}
              >
                {typeof content === "string" ? (
                  <p className="my-auto">{content}</p>
                ) : (
                  content
                )}
              </ToastWrapper>
            ),
            id,
          },
          ...previousToasts,
        ]);
      },
    }),
    [setToasts]
  );

  return (
    <ToastContext.Provider value={value}>
      {toasts.length > 0 && (
        <div className="fixed xs:right-6 top-28 z-50 flex flex-col gap-1 2xs:left-auto 2xs:right-auto">
          {toasts.map((t) => t.element)}
        </div>
      )}
      {children}
    </ToastContext.Provider>
  );
}
