import { NavLink } from "react-router-dom";
import ButtonLink from "components/buttons/ButtonLink";
import { concat } from "util/string";
import Icon from "assets/Icon";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import AppConstants from "appconstants.json";
import routes from "routes/routes";
import Recall from "components/Footer/Recall";
import {
  getCompanyNameWithProOverride,
  getVendorFromCurrentHost,
} from "util/host";
import ChatButton from "components/Header/components/Support/ChatButton";

const FooterThemeFallback = "bg-[#261F1B] text-white";
const FooterTheme = "footer";

// TODO: Implement Support page at /support route with instructions
const enableSupportPageLinks = AppConstants.enableSupportPageLinks;

type FooterLink = { href: string; label: string };
export const footerLinks: FooterLink[] = [
  {
    href: routes.MyAccount.getPath(),
    label: "My Account",
  },
  {
    href: routes.OrderHistory.getPath(),
    label: "View Orders",
  },
  {
    href: routes.RapidReorder.getPath(),
    label: "Rapid Reorder",
  },
  {
    href: routes.Policies.getPath(),
    label: "Policies",
  },
  {
    href: routes.AccessibilityPolicy.getPath(),
    label: "Accessibility Policy",
  },
  {
    href: routes.FAQ.getPath(),
    label: "FAQ",
  },
];

export default function Footer(): React.ReactElement {
  const { data: userLayoutData } = useLayoutAPI();

  return (
    <footer
      id="FooterWrapper"
      className={concat(
        FooterThemeFallback,
        FooterTheme,
        "max-w-full",
        "py-10",
        "flex",
        "flex-col",
        "place-items-center",
        "place-content-center",
        "space-x-2"
      )}
    >
      <div
        id="FooterPrimaryLinkContainer"
        className={concat(
          "flex",
          "flex-col lg:flex-row",
          "gap-2 lg:gap-10",
          "place-items-center",
          "font-semibold"
        )}
      >
        {footerLinks.map((link) => (
          <NavLink
            key={link.label}
            to={link.href}
            className={({ isActive }): string =>
              concat("hover:underline", isActive && "underline")
            }
            reloadDocument
            end // use exact match
          >
            {link.label}
          </NavLink>
        ))}
        <Recall
          className="hover:underline"
          recallNoticeUrl="https://www.lancastertableandseating.com/recalls"
          recallNoticeEndDate="02/25/2025"
        />
        {enableSupportPageLinks && (
          <ButtonLink
            id="footer-support-link"
            href={routes.Support.getPath()}
            variant="primary"
            className="flex h-8 min-w-max flex-row place-items-center space-x-2 rounded p-3"
            icon={<Icon name="chat" className="size-4x4" fill="none" />}
            iconOrientation="left"
            label="Support"
          />
        )}
        {userLayoutData?.isSwitchboardEnabled && (
          <ChatButton
            encryptedUserIndex={
              userLayoutData.baseAccount?.encryptedUserIndex ?? ""
            }
            siteData={userLayoutData.siteData}
            id="footer-chat-button"
          />
        )}
      </div>
      <FooterCopyright />
    </footer>
  );
}

export function FooterCopyright(): React.ReactElement {
  const { data: userLayoutData } = useLayoutAPI();

  const companyName = getCompanyNameWithProOverride(userLayoutData);
  const vendor = getVendorFromCurrentHost();

  let copyrightText = "";
  if (userLayoutData) {
    copyrightText = `© Copyright ${new Date().getFullYear()} ${companyName} — All rights reserved.`;
  }

  if (
    AppConstants.enableLogoRebranding &&
    vendor === "pro" &&
    copyrightText?.length > 0
  ) {
    return (
      <span
        data-testid="FooterCopyright"
        className="mt-4 text-center text-sm font-normal"
      >
        {"Pro Marketplace is Powered by "}
        <strong>{"Clark"}</strong>
        {"Pro"}
        <sub>{"™"}</sub>
        {". " + copyrightText}
      </span>
    );
  }

  return (
    <span
      data-testid="FooterCopyright"
      className="mt-4 text-center text-sm font-normal"
    >
      {copyrightText}
    </span>
  );
}
