import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";
import Button from "components/buttons/Button";
import useOnLocationChange from "util/hooks/useOnLocationChange";
import useToastFadeOut from "components/Toast/useToastFadeOut";
import { ToastType } from "contexts/toast/Toast.types";
import { concat } from "util/string";

export default function ToastWrapper({
  children,
  closeToast,
  displayDuration,
  transitionDuration,
  type = "custom",
}: {
  children: JSX.Element;
  closeToast: () => void;
  displayDuration: number;
  transitionDuration: number;
  type?: ToastType;
}): JSX.Element {
  const { opacity, onMouseEnter, onMouseLeave, closeWithTimers } =
    useToastFadeOut(closeToast, displayDuration, transitionDuration);

  useOnLocationChange(closeWithTimers);

  return (
    <div
      role="alert"
      className={concat(
        "max-w-[400px] w-full p-4 pr-6 relative rounded-xl shadow transition-opacity ease-in flex gap-4 z-[100]",
        type === "custom" && "bg-white",
        type === "success" && "bg-green-50",
        type === "error" && "bg-red-50",
        type === "info" && "bg-gray-100"
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        opacity,
        transitionDuration: `${transitionDuration}ms`,
      }}
    >
      <ToastIcon type={type} />
      <Button
        className="h-5 w-5 absolute right-2 top-2"
        hideLabel={true}
        icon={<XIcon name="cross" className="text-gray-900 h-full w-full" />}
        label="Close Toast"
        onClick={closeWithTimers}
        size="custom"
        variant="unstyled"
      />
      {children}
    </div>
  );
}

function ToastIcon({ type }: { type: ToastType }): JSX.Element | null {
  const sharedIconClassNames = "shrink-0 my-auto h-10 w-10 p-1";

  if (type === "success") {
    return (
      <CheckCircleIcon
        className={concat(sharedIconClassNames, "text-green-600")}
      />
    );
  }

  if (type === "error") {
    return (
      <ExclamationCircleIcon
        className={concat(sharedIconClassNames, "text-red-600")}
      />
    );
  }

  if (type === "info") {
    return (
      <InformationCircleIcon
        className={concat(sharedIconClassNames, "text-gray-600")}
      />
    );
  }

  return null;
}
