import { Link } from "react-router-dom";
import {
  LogoutIcon,
  SwitchHorizontalIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { memo, useRef, useState } from "react";
import ButtonGroup from "components/buttons/ButtonGroup";
import { useUserContext } from "authentication/contexts/user.context";
import MenuButton from "components/buttons/MenuButton";
import { HeaderButtonGroupTheme } from "components/Header/Header";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import useAccountLinks from "layouts/Account/useAccountLinks";
import routes from "routes/routes";
import useAccountLinksAPI from "layouts/api/useAccountLinksAPI";
import { buttonTertiaryTheme } from "components/buttons/buttons.styles";
import { concat } from "util/string";
import { toggle } from "components/Modal/Modal";
import { requestQuoteModalId } from "components/Quotes/RequestQuoteModal";
import { ButtonProps } from "components/buttons/Button.types";
import Icon from "assets/Icon";
import Popover from "components/Popover";
import SupportPopOver from "components/Header/components/Support/SupportPopOver";

function HeaderRightButtonGroup({
  hideButtonLabels = false,
}: {
  hideButtonLabels: boolean;
}): JSX.Element {
  const userContext = useUserContext();
  const { data: userLayoutData } = useLayoutAPI();
  const [showPopOver, setShowPopOver] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const { data: accountLinksData } = useAccountLinksAPI();
  const visibleAccountLinks = useAccountLinks(true);

  const menuItems = visibleAccountLinks.map((accountLink) => (
    <Link
      key={accountLink.title}
      to={accountLink.href}
      className="flex flex-row items-center gap-2"
    >
      <span className="size-5x5 text-gray-400 items-center mb-2">
        {accountLink.icon}
      </span>
      {accountLink.title}
    </Link>
  ));

  if (userLayoutData?.baseAccount?.hasMultiAccounts ?? false) {
    menuItems.push(
      <Link
        to={routes.LoginMultiAccount.getPath()}
        key="switch-company"
        data-testid="switch-company-button"
        className="flex w-full flex-row items-center space-x-2"
      >
        <SwitchHorizontalIcon className="size-5x5 text-gray-400" />
        <span>Switch Company</span>
      </Link>
    );
  }

  const hasPunchOutSession = userLayoutData?.hasPunchOutSession ?? false;
  if (!hasPunchOutSession) {
    menuItems.push(
      <button
        key="logout"
        data-testid="logout-button"
        className="flex w-full flex-row items-center gap-2"
        onClick={(): void => userContext.logout()}
      >
        <LogoutIcon name="logout" className="size-5x5 text-gray-400" />
        <span>Logout</span>
      </button>
    );
  }

  if (accountLinksData?.isRequestQuoteAllowed) {
    menuItems.push(
      <button
        className={concat(buttonTertiaryTheme, " w-full")}
        tabIndex={-1}
        role="menuitem"
        onClick={(): void => toggle(requestQuoteModalId)}
      >
        Request a Quote
      </button>
    );
  }

  const supportIcon = <Icon name="chat" className="size-5x5" fill="none" />;
  const supportBtnProps: ButtonProps = {
    id: "header-chat-button",
    icon: supportIcon,
    iconOrientation: "left",
    label: "Support",
    labelClassName: "",
    size: "group",
    variant: "group",
    hideLabel: hideButtonLabels,
  };

  const closeAndRefocus = (): void => {
    setShowPopOver(false);
    triggerRef.current?.focus();
  };

  return (
    <ButtonGroup className={HeaderButtonGroupTheme}>
      {userLayoutData && (
        <Popover
          buttonProps={supportBtnProps}
          showPopOver={showPopOver}
          setShowPopOver={setShowPopOver}
          triggerRef={triggerRef}
          handleCloseAndRefocus={closeAndRefocus}
        >
          <SupportPopOver
            className="rounded-lg overflow-auto max-h-[100vh-160px] w-[calc(100vw-16px)] xs:max-h-max xs:w-[500px] absolute right-3 z-50"
            accountSpecialist={userLayoutData.accountSpecialist}
            encryptedUserIndex={
              userLayoutData.baseAccount?.encryptedUserIndex ?? ""
            }
            isSpecialistPhoneNumberVisible={
              userLayoutData.isSpecialistPhoneNumberVisible
            }
            isSwitchboardEnabled={userLayoutData.isSwitchboardEnabled}
            siteData={userLayoutData.siteData}
            onSubmit={(): void => {
              closeAndRefocus();
            }}
          />
        </Popover>
      )}
      <MenuButton
        id="header-account-button"
        key="header-account-button"
        variant="group"
        size="group"
        icon={<UserIcon name="person" className="size-4x4" strokeWidth={2.5} />}
        iconOrientation="left"
        label="Account"
        hideLabel={hideButtonLabels}
        menu={menuItems}
        menuPosition="bottom-right"
      />
    </ButtonGroup>
  );
}

export default memo(HeaderRightButtonGroup);
